// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Component elements
import AppDrawerPlaceholder from "./App_DrawerPlaceholder";
import VolunteersContentInformation from "./volunteers/Volunteers_ContentInformation";
import VolunteersContentCurrentEnrollment from "./volunteers/Volunteers_ContentCurrentEnrollment";
import VolunteersContentTimepoints from "./volunteers/Volunteers_ContentTimepoints";
// -------------------------------------------------
// Support functions
import { getTicketsForUserAndTimepoint } from "../supportFunc/getTicketsForUserAndTimepoint";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../redux/reducers/studies";
import {
  ticketsSelectors,
  postTicketList,
  deleteTicketListById,
} from "../redux/reducers/tickets";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const VolunteersContent = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "volunteers.Volunteers_Content",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const studyEntities = useSelector((state) =>
    studiesSelectors.selectEntities(state),
  );
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const consumersStatus = useSelector((state) => state.consumers.status);
  const currentUserId = useSelector(
    (state) => state.user.currentUser && state.user.currentUser._id,
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Edits the current consumer after it's been edited in the top drawer
  const handleMutateCurrentConsumer = async (method, obj) => {
    // Init
    let newTickets;
    // Make a copy of the object
    let newConsumer = JSON.parse(JSON.stringify(props.currentConsumer));
    // Check what to change
    switch (method) {
      case "current-enrollment":
        if (obj.userStudyNumber) {
          newConsumer.studyEnrollmentList[0].userStudyNumber =
            obj.userStudyNumber;
        }
        // Check if user is assigned to a new group
        if (
          newConsumer.studyEnrollmentList[0].groupAssignmentList.length === 0 ||
          newConsumer.studyEnrollmentList[0].groupAssignmentList[0]._id !==
            obj.groupId
        ) {
          // Assign new group
          newConsumer.studyEnrollmentList[0].groupAssignmentList.unshift({
            groupId: obj.groupId,
            assignmentDate: DateTime.now().toISODate(),
          });
          // Check if any new tickets should be created.
          studyEntities[
            newConsumer.studyEnrollmentList[0].studyId
          ].timepointList.forEach((timepoint) => {
            newTickets = getTicketsForUserAndTimepoint(
              newConsumer,
              studyEntities[newConsumer.studyEnrollmentList[0].studyId],
              timepoint._id,
              "taskResponses",
            );
            // If there are no new tickets, this user is not assigned to a group of this timepoint,
            // ... so delete all existing tickets with this timepoint id
            if (!newTickets) {
              dispatch(
                deleteTicketListById({
                  socket,
                  requestingUser: currentAuth,
                  body: {
                    data: ticketList
                      .filter(
                        (t) =>
                          t.userId === newConsumer._id &&
                          t.timepointId === timepoint._id,
                      )
                      .map((t) => t._id),
                  },
                }),
              );
              return;
            }
            // Create any new ticket if it does not exist already
            newTickets = newTickets.filter(
              (ticket) =>
                !ticketList.some(
                  (t) =>
                    t.userId === ticket.userId &&
                    t.studyId === ticket.studyId &&
                    t.timepointId === ticket.timepointId &&
                    t.measurementId === ticket.measurementId &&
                    t.surveyId === ticket.surveyId &&
                    (t.entryNumber === ticket.entryNumber ||
                      ticket.repeat === -1),
                ),
            );
            newTickets.length > 0 &&
              dispatch(
                postTicketList({
                  socket,
                  requestingUser: currentAuth,
                  body: {
                    data: newTickets,
                    meta: { userId: currentUserId, ticketsToCreate: "new" },
                  },
                }),
              );
          });
        }
        break;
      default:
        return;
    }
    props.handlePatchCurrentConsumer(newConsumer);
  };
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return !props.currentConsumer ? (
    <AppDrawerPlaceholder
      imageURL="images/icon-choose.png"
      imageAlt={t("Choose a volunteer from the list")}
      title={t("Choose a volunteer from the list")}
    />
  ) : (
    <div className="mb-2">
      <VolunteersContentInformation currentConsumer={props.currentConsumer} />
      {props.currentConsumer.studyEnrollmentList.length > 0 && (
        <VolunteersContentCurrentEnrollment
          handleMutateCurrentConsumer={handleMutateCurrentConsumer}
          currentConsumer={props.currentConsumer}
        />
      )}
      {consumersStatus === "succeeded" && (
        <VolunteersContentTimepoints
          currentConsumer={props.currentConsumer}
          timepointList={
            props.currentConsumer.studyEnrollmentList.length > 0 &&
            studyEntities[props.currentConsumer.studyEnrollmentList[0].studyId]
              ? studyEntities[
                  props.currentConsumer.studyEnrollmentList[0].studyId
                ].timepointList
              : []
          }
          handlePatchCurrentConsumer={props.handlePatchCurrentConsumer}
          handleMutateCurrentConsumer={handleMutateCurrentConsumer}
        />
      )}
    </div>
  );
};
// =================================================
// EXPORT COMPONENT
export default VolunteersContent;
